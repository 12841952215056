/* eslint-disable no-useless-escape */
/* eslint-disable import/prefer-default-export */
const camelCaseToString = (camelCasedStr) => {
  const temp = camelCasedStr.replace(/([A-Z])/g, ' $1');
  return temp.charAt(0).toUpperCase() + temp.slice(1);
};

function mobileOperatingSystem() {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;

  // Windows Phone must come first because its UA also contains "Android"
  if (/windows phone/i.test(userAgent)) {
    return 'Windows Phone';
  }

  if (/android/i.test(userAgent)) {
    return 'Android';
  }

  // iOS detection from: http://stackoverflow.com/a/9039885/177710
  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return 'iOS';
  }

  return 'unknown';
}

function isValidEmail(email) {
  const isValid = new RegExp(`${/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))/.source
  }${/@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.source}`)
    .test(email);

  if (isValid) return true;
  return false;
}

function vhToPx(value) {
  const w = window;
  const d = document;
  const e = d.documentElement;
  const g = d.getElementsByTagName('body')[0];
  const x = w.innerWidth || e.clientWidth || g.clientWidth;
  const y = w.innerHeight || e.clientHeight || g.clientHeight;

  const result = (y * value) / 100;
  return result;
}

export function scrollbarVisible(element) {
  if (element) {
    return element.scrollHeight > element.clientHeight;
  }

  return false;
}

const getHost = (url) => {
  // let domain = url;
  // if (url.includes('://')) {
  //   // eslint-disable-next-line prefer-destructuring
  //   domain = url.split('://')[1];
  // }
  const subdomain = window.location.host.replaceAll('.', '-');
  return subdomain;
};

export const transformConfigToFrontend = (c) => {
  try {
    localStorage.setItem('org-temp', JSON.stringify({
      logo: c?.logoUrl ?? '',
      mainColor: c?.theme?.primaryColor ?? '',
      issuerKey: c?.issuerKey ?? '',
      loaderStyle: `border-bottom: 2px solid ${c?.theme?.primaryColor ?? '#ffffff'}; border-top: 2px solid ${c?.theme?.primaryColor ?? '#ffffff'};`
    }));
    return {
      ...c,
      theme: {
        main: c?.theme?.primaryColor ?? '#047FFF',
        mainShade: c?.theme?.backgroundColor ?? '#e8ecf4',
        text: c?.font?.color ?? '#000000',
        button: c?.theme?.primaryColor ?? '#047FFF',
        font: c?.font?.name ?? 'Lato',
        fontUrl: c?.font?.url ?? null
      },
      loadingLogoTheme: {
        spinner: [
          c?.theme?.primaryColor ?? '#047FFF',
          c?.theme?.primaryColor ?? '#047FFF'
        ]
      },
      metadata: {
        favIcon: c?.metadata?.favIcon ?? c?.logoUrl ?? '',
        title: `Digital Credentials Portal for ${c?.organizationName ?? ''}`,
        description: 'One stop solution for all your digital credentials',

        // snapshot: 'https://storage.googleapis.com/indisi-images-dev/afa604b466b9079a827bce2dfe2bfcd59554059c4d47b17d176f34dc779969da'
      },
      logo: {
        whiteImage: c?.logoUrl ?? '',
        coloredImage: c?.logoUrl ?? '',
        text1: c?.organizationName ?? '',
        text2: c?.organizationAlternateName ?? ''
      },
      pageTitle: c?.metadata?.title ?? '',
      instructions: c?.instructions ?? '',
      faq: c?.faq ?? [],
      issuer: {
        name: c?.organizationName ?? '',
        email: c?.contactEmail ?? ''
      },
      links: {
        domain: c?.portalUrl ?? '',
        vaas: ''
      },
      bannerText: c?.marquee?.enabled ? c?.marquee?.text : null,
      carousel: c?.carouselImageUrls ?? [],

      issuerUid: c?.orgId ?? '',
      uniqueIdentifierLabel: c?.uniqueIdentifierLabel ?? 'Roll Number',
      configId: c?.configId ?? '-'
    };
  } catch (e) {
    console.error(e);
    window.snackbar('Config invalid');
    // window.replace('https://trential.com');
  }
};

export const addFont = (name, url) => {
  const link = document.createElement('style');
  link.setAttribute('class', 'custom-font');
  link.textContent = `@font-face{font-family:"${name}"; src:url("${process.env.REACT_APP_CORS_ENDPOINT}/${url}")}`;
  document.head.appendChild(link);
};

export { camelCaseToString, getHost, isValidEmail, mobileOperatingSystem, vhToPx };
